.snap {
  text-align: center;
  margin-top: 20%;
  font-size: 1.5em;
}
.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #e151ab;
  color: #e151ab;
  box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  animation: dotTyping 1.5s infinite linear;
}

.loading {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-fullpage {
  height: 100vh;
  max-height: 100%;
}

.loading-block {
  height: 50vh;
  max-height: 100%;
}

.loading .loader {
  position: absolute;
  transform: translateX(-50%);
  top: 40%;
  left: 50%;
}

.loading-fullpage .loader {
  transform: translateX(-50%) scale(4);
}

.loadingMsg {
  font-size: 18px;
  color: '#aaa';
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  }
  50% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px -10px 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px -10px 0 0 #e151ab;
  }
  100% {
    box-shadow: 9984px 0 0 0 #e151ab, 9999px 0 0 0 #e151ab, 10014px 0 0 0 #e151ab;
  }
}

*::-webkit-scrollbar {
  width: 14px;
  height: 14px;
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #555555aa;
  border-radius: 16px;
  width: 6px;
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
*::-webkit-scrollbar-thumb:hover {
  width: 8px;
  height: 8px;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-corner {
  display: none;
}

.errorInfo {
  color: #555555aa;
  font-size: 10px;
}

@media screen and (orientation: portrait) {
  html.forceLandscape {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 85vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.popout {
  margin: 10px;
}